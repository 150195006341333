import { useAuth } from '../../context/AuthContext';
import Loading from '../Loading/Lazy';

function Logout() {
  const { isAuthenticated, signOut } = useAuth();

  if (isAuthenticated) {
    signOut('/', true);
  }

  return <Loading />;
}

export default Logout;
