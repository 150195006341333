/* eslint-disable import/prefer-default-export */
import { useEffect } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import { useAuth } from '../context/AuthContext';
import LoadingLoadable from '../pages/Loading/Lazy';

interface IPrivateRoute {
  redirectTo?: string
}

export function PrivateRoute({
  redirectTo = '/',
}: IPrivateRoute) {
  const { isAuthenticated, getUserData, loadingUserData } = useAuth();

  useEffect(() => {
    getUserData();
  }, []);

  if (loadingUserData) {
    return <LoadingLoadable />;
  }

  if (!isAuthenticated && !loadingUserData) {
    return <Navigate to={redirectTo} />;
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <><Outlet /></>;
}
