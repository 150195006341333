import React from 'react';
import { Button } from 'react-bootstrap';
import style from './style.module.css';

interface IProps{
    bgColor?: string;
    textColor?: string;
    className?: string;
    text?: string;
    onClick?: any;
    disabled?: boolean;
    type?: 'button' | 'submit' | 'reset';
    loadingBg?: string;
    isLoading?: boolean;
}

export default function ButtonComponent({
  bgColor, textColor, loadingBg, className, text, onClick, disabled = false, type, isLoading,
}: IProps) {
  return (
    <Button
      type={type}
      disabled={disabled}
      onClick={onClick}
      style={{
        background: bgColor,
        boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.25)',
        borderRadius: '12px',
        border: 'none',
        color: textColor,
        position: 'relative',
        overflow: 'hidden',
      }}
      className={className}
    >
      {isLoading
            && (
            <div className={style.containerBtn}>
              <div className={style.bar} style={{ background: loadingBg }} />
            </div>
            )}
      {text}
    </Button>
  );
}
