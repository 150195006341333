/* eslint-disable import/prefer-default-export */
import { useContext } from 'react';
import { Outlet } from 'react-router-dom';

import { AuthContext } from '../context/AuthContext';
import LoadingLoadable from '../pages/Loading/Lazy';

export function PublicRoute() {
  const { isAuthenticated, loadingUserData } = useContext(AuthContext);

  if (loadingUserData) {
    return <LoadingLoadable />;
  }

  if (isAuthenticated && !loadingUserData) {
    window.location.replace('/dashboard');
  }
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <Outlet />;
}
