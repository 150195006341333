import Form from 'react-bootstrap/Form';
import Select from 'react-select';
import InputGroup from 'react-bootstrap/InputGroup';
import style from './style.module.css';

interface ISelect{
    value: string | number;
    label: string;
}

interface IProps{
    type?: string;
    label?: string;
    placeholder?: string;
    isBig?: boolean;
    isTextArea?: boolean;
    isSelect?: boolean;
    options?: ISelect[];
    defaultOptions?: ISelect;
    isLabel?: boolean;
    className?: string;
    required?: boolean;
    handleChange?: (e:any) => void;
    handleBlur?: (e:any) => void;
    values?: any;
    name?: string;
    readOnly?: boolean;
    isMultiSelect?: boolean
    accepted?: string;
    withPhone?: boolean;
    isClearable?: boolean;
    isSearchable?: boolean;
    withFormatSelect?: boolean;
    isDisabled?: boolean;
}

export default function Input({
  type,
  label,
  placeholder,
  isBig = false,
  isTextArea = false,
  isSelect = false,
  options,
  defaultOptions,
  isLabel = true,
  className,
  required = true,
  handleChange,
  handleBlur,
  values,
  name,
  readOnly = false,
  isMultiSelect = false,
  accepted,
  withPhone = false,
  isClearable = false,
  isSearchable = false,
  withFormatSelect = false,
  isDisabled = false,
}: IProps) {
  const hospitalID = localStorage.getItem('hospitalID');
  return (
    <>
      {isLabel && <Form.Label className="font-poppins-400"><b>{label}</b></Form.Label>}
      {isTextArea
        && (
        <Form.Control
          as="textarea"
          rows={4}
          className={style[`input${hospitalID}`]}
          required={required}
          onChange={handleChange}
          onBlur={handleBlur}
          value={values}
          autoComplete="new-off"
          name={name}
          readOnly={readOnly}
        />
        )}
      { isSelect
          && (
            <Select
              isDisabled={isDisabled}
              placeholder={placeholder}
              options={options}
              defaultValue={defaultOptions}
              className={`${style[`input${hospitalID}`]} ${className}`}
              classNames={{
                control: () => style['input-select-green'],
              }}
              onChange={handleChange}
              name={name}
              required
              value={values}
              isOptionDisabled={() => readOnly}
              isSearchable={readOnly ? false : isSearchable}
              isClearable={readOnly ? false : isClearable}
              isMulti={isMultiSelect}
              menuPortalTarget={document.body}
              styles={{ menuPortal: (base: any) => ({ ...base, zIndex: 9999 }) }}
              // eslint-disable-next-line react/jsx-no-bind, react/no-unstable-nested-components
              formatOptionLabel={function (data) {
                return (
                  withFormatSelect
                    ? <span dangerouslySetInnerHTML={{ __html: data.label }} /> : data.label
                );
              }}
            />
          )}

      {withPhone && (
      <InputGroup>
        <InputGroup.Text className="input-group-text">+62</InputGroup.Text>
        <Form.Control
          name={name}
          required
          onChange={handleChange}
          onBlur={handleBlur}
          value={values}
          className={`${style[`input${hospitalID}`]} ${isBig && style.big}`}
        />
      </InputGroup>
      )}

      {!isTextArea && !isSelect && !withPhone ? (
        <Form.Control
          type={type}
          className={`${style[`input${hospitalID}`]} ${isBig && style.big}`}
          placeholder={placeholder}
          required={required}
          onChange={handleChange}
          onBlur={handleBlur}
          value={values}
          autoComplete="new-off"
          name={name}
          readOnly={readOnly}
          accept={accepted}
        />
      ) : null}
    </>
  );
}
