/* eslint-disable import/no-extraneous-dependencies */
import { useMemo, useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import { Navigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import style from './style.module.css';
import Register from './Register';
import Otp from './Otp';
import Input from './Input';
import NavBar from '../../components/NavBar';

export default function Login() {
  const { t } = useTranslation('common');
  const [loadPage, setLoadPage] = useState('register');
  const [phone, setPhone] = useState(null);

  const hospitalID = localStorage.getItem('hospitalID');
  const hospitalDetail = JSON.parse(localStorage.getItem('hospitalDetail'));

  const page = useMemo(() => {
    switch (loadPage) {
      case 'register':
        return (
          <Register
            hospitalID={hospitalID}
            changePhone={(phoneParam: string) => setPhone(phoneParam)}
            onSuccess={(pageParam: string) => setLoadPage(pageParam)}
          />
        );
      case 'otp':
        return (
          <Otp
            phone={phone}
            hospitalID={hospitalID}
            onSuccess={(pageParam: string) => setLoadPage(pageParam)}
          />
        );
      case 'input':
        return (
          <Input
            hospitalID={hospitalID}
          />
        );
      default:
        return false;
    }
  }, [loadPage, phone, hospitalID]);

  if (!hospitalID) {
    return <Navigate to="/" />;
  }

  return (
    <>
      <Helmet>
        <title>{hospitalDetail?.name}</title>
        <link id="favicon" rel="icon" type="image/png" href={hospitalDetail?.image} sizes="16x16" />
      </Helmet>

      <div className={style['bg-login']}>
        <NavBar logo={hospitalDetail?.image} text={hospitalDetail?.name} withMenu={false} />
        <Container className="mt-5 pt-4 pb-5">
          <Row>
            <Col md={6}>
              <h2 className={`text-white font-poppins-900 ${style['box-text']}`}>
                {t('login.title1')}
                {' '}
                <br />
                {' '}
                {t('login.title2')}
              </h2>
              <p className="text-white font-poppins-400">
                {t('login.subtitle')}
              </p>
            </Col>
            <Col md={6}>
              <div className={`${style['box-white']} pr-1 pl-1 pb-4 pt-4`}>
                {page}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}
