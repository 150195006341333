/* eslint-disable react/no-array-index-key */
import { useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Link } from 'react-router-dom';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useTranslation } from 'react-i18next';
import Dropdown from 'react-bootstrap/Dropdown';
import style from './style.module.css';
import Bell from '../../assets/img/bell.svg';
import { useAuth } from '../../context/AuthContext';

interface IMenuProps {
  name: string;
  route: string;
  isDrodown: boolean;
  drodown: any;
}

interface IProps{
  logo: string;
  text: string;
  withMenu?: boolean;
  location?: string;
  menu?: IMenuProps[];
}

export default function NavBar({
  logo, text, withMenu = true, location, menu,
}: IProps) {
  const { user, isAuthenticated } = useAuth();
  const { t, i18n } = useTranslation('common');

  useEffect(() => {
    if (!localStorage.getItem('lang')) {
      localStorage.setItem('lang', 'id');
      i18n.changeLanguage('id');
    } else {
      i18n.changeLanguage(localStorage.getItem('lang'));
    }
  }, []);

  const handleLang = (lang: string) => {
    localStorage.setItem('lang', lang);
    i18n.changeLanguage(lang);
  };

  const hospitalID = localStorage.getItem('hospitalID');

  const theLang = localStorage.getItem('lang') ? localStorage.getItem('lang') : i18n.language;

  return (
    <Navbar sticky="top" expand="md" className="pt-3 pb-3" style={{ backgroundColor: '#fff', boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)' }}>
      <Container>
        <Navbar.Brand href="/" className="d-flex align-items-center">
          <LazyLoadImage
            alt={text}
            src={logo}
            width={25}
            style={{ marginRight: 10 }}
          />
          <div
            dangerouslySetInnerHTML={{ __html: text }}
            className="font-poppins-500"
            style={{
              float: 'right', fontSize: '0.7em', letterSpacing: '0.05em', lineHeight: '21px', textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
            }}
          />
        </Navbar.Brand>
        {withMenu
        && isAuthenticated ? (
          <>
            <Navbar.Toggle aria-controls="offcanvasNavbar-expand-md" />
            <Navbar.Offcanvas
              id="offcanvasNavbar-expand-md"
              aria-labelledby="offcanvasNavbarLabel-expand-md"
              placement="end"
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title id="offcanvasNavbarLabel-expand-md">
                  <div
                    dangerouslySetInnerHTML={{ __html: text }}
                    className="font-poppins-500"
                    style={{
                      float: 'right', fontSize: '0.8em', letterSpacing: '0.05em', lineHeight: '21px', textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                    }}
                  />
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Nav className="justify-content-end flex-grow-1 pe-3">
                  {menu.length > 0 && menu.map((item) => (
                    <div key={item.name}>
                      {item.isDrodown
                        ? (
                          <NavDropdown title={t(`menu.${item.name}`)} id="basic-nav-dropdown" className={`font-poppins-700 ${style['item-drodowns']} ${item.drodown.some((e) => (e.route === location)) && style[`actived-drop${hospitalID}`]}`}>
                            {item.drodown.map((d, idx) => (
                              <NavDropdown.Item key={idx} href={d.route} className={`${d.route === location ? style[`actived${hospitalID}`] : style.noactive}`}>
                                {t(`menu.${d.name}`)}
                              </NavDropdown.Item>
                            ))}
                          </NavDropdown>
                        )
                        : <Nav.Link href={item.route} className={`font-poppins-700 ${style['item-navbar']} ${item.route === location ? style[`actived${hospitalID}`] : style.noactive}`}>{t(`menu.${item.name}`)}</Nav.Link>}
                    </div>
                  ))}
                </Nav>
                <div className="d-flex">
                  <NavDropdown title={user?.name?.toUpperCase()} className={`item-navbar font-poppins-700 ${style[`user-bg${hospitalID}`]}`}>
                    <NavDropdown.Item onClick={() => window.location.assign('/profile')}><b>{t('navbar.profile')}</b></NavDropdown.Item>
                    <NavDropdown.Item onClick={() => window.location.assign('/logout')}><b style={{ color: 'red' }}>{t('navbar.logout')}</b></NavDropdown.Item>
                  </NavDropdown>
                  <Link to="/notification" className="d-flex justify-content-center flex-column">
                    <LazyLoadImage
                      alt="notification"
                      src={Bell}
                      width={20}
                      style={{ marginLeft: 10, cursor: 'pointer' }}
                    />
                  </Link>
                  <Dropdown>
                    <Dropdown.Toggle variant="default" style={{ backgroundColor: '#fff' }}>
                      <b>{theLang?.toUpperCase()}</b>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item onClick={() => handleLang('id')} active={theLang === 'id'}>Bahasa Indonesia</Dropdown.Item>
                      <Dropdown.Item onClick={() => handleLang('en')} active={theLang === 'en'}>English (US) </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </>
          ) : (
            <div className="d-flex">
              <Dropdown>
                <Dropdown.Toggle variant="default" style={{ backgroundColor: '#fff' }}>
                  <b>{theLang?.toUpperCase()}</b>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => handleLang('id')} active={theLang === 'id'}>Bahasa Indonesia</Dropdown.Item>
                  <Dropdown.Item onClick={() => handleLang('en')} active={theLang === 'en'}>English (US) </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          )}
      </Container>
    </Navbar>
  );
}
