/**
 * Composing <Route> in React Router v6
 * https://gist.github.com/mjackson/d54b40a094277b7afdd6b81f51a0393f
 *
 * Upgrading from v5
 * https://reactrouter.com/docs/en/v6/upgrading/v5
 */
import {
  Routes, Route, useLocation, useSearchParams,
} from 'react-router-dom';
import { PublicRoute } from './PublicRoute';
import { PrivateRoute } from './PrivateRoute';

import Home from '../pages/Home/Lazy';
import Login from '../pages/Login';
import Dashboard from '../pages/Dashboard/Lazy';
import Queue from '../pages/Queue/Lazy';
import Poli from '../pages/Service/Poli/Lazy';
import PoliDetail from '../pages/Service/Poli/Detail/Lazy';
import TicketPoli from '../pages/Ticket/Poli/Lazy';
import TicketGeneral from '../pages/Ticket/GeneralService/Lazy';
import Ticket from '../pages/Ticket/Lazy';
import GeneralService from '../pages/Service/GeneralService/Lazy';
import History from '../pages/History/Lazy';
import Logout from '../pages/Logout';
import Profile from '../pages/Profile/Lazy';
import Notif from '../pages/Notif/Lazy';
import PrivacyPolicy from '../pages/PrivacyPolicy/Lazy';
import TermCondition from '../pages/TermCondition/Lazy';

// eslint-disable-next-line import/prefer-default-export
export function RouteList() {
  return (
    <Routes>

      <Route element={<PublicRoute />}>
        <Route
          path="/"
          element={<Home />}
        />
      </Route>

      <Route element={<PublicRoute />}>
        <Route
          path="/login"
          element={<Login />}
        />
      </Route>

      <Route element={<PrivateRoute redirectTo="/" />}>
        <Route
          path="/dashboard"
          element={<Dashboard />}
        />
      </Route>

      <Route element={<PrivateRoute redirectTo="/" />}>
        <Route
          path="/queue"
          element={(
            <Queue />
          )}
        />
      </Route>

      <Route path="/poli" element={<PrivateRoute redirectTo="/" />}>
        <Route index element={<Poli />} />
        <Route path=":scheduleId" element={<PoliDetail />} />
      </Route>

      <Route path="/service" element={<PrivateRoute redirectTo="/" />}>
        <Route index element={<h1>404</h1>} />
        <Route path=":service" element={<GeneralService />} />
      </Route>

      <Route element={<PrivateRoute redirectTo="/" />}>
        <Route
          path="/ticket/poli/:orderId"
          element={(
            <TicketPoli />
          )}
        />
      </Route>

      <Route path="/ticket/service" element={<PrivateRoute redirectTo="/" />}>
        <Route index element={<h1>Halaman Tidak Ditemukan</h1>} />
        <Route path=":service/:orderId" element={<TicketGeneral />} />
      </Route>

      <Route path="/order">
        <Route index element={<h1>Halaman Tidak Ditemukan</h1>} />
        <Route path=":service" element={<h1>Halaman Tidak Ditemukan</h1>} />
        <Route path=":service/:orderId" element={<Ticket />} />
      </Route>

      <Route element={<PrivateRoute redirectTo="/" />}>
        <Route
          path="/history"
          element={(
            <History />
          )}
        />
      </Route>

      <Route element={<PrivateRoute redirectTo="/" />}>
        <Route
          path="/logout"
          element={(
            <Logout />
          )}
        />
      </Route>

      <Route element={<PrivateRoute redirectTo="/" />}>
        <Route
          path="/profile"
          element={(
            <Profile />
          )}
        />
      </Route>

      <Route element={<PrivateRoute redirectTo="/" />}>
        <Route
          path="/notification"
          element={(
            <Notif />
          )}
        />
      </Route>

      <Route path="/privacy-policy" element={(<PrivacyPolicy />)} />

      <Route
        path="/term-condition"
        element={<TermCondition />}
      />

      <Route path="*" element={<h1>Halaman Tidak Ditemukan</h1>} />
    </Routes>
  );
}
