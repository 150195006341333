/* eslint-disable camelcase */
/* eslint-disable import/no-extraneous-dependencies */
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';
import { BrowserRouter } from 'react-router-dom';

import { ToastContainer } from 'react-toastify';
import { AuthProvider } from './context/AuthContext';
import { RouteList } from './routes';
import './assets/css/global.css';
import './assets/css/fonts.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import common_id from './utils/lang/id.json';
import common_en from './utils/lang/en.json';

i18next.init({
  interpolation: { escapeValue: false }, // React already does escaping
  lng: localStorage.getItem('lang') ? localStorage.getItem('lang') : 'id', // language to use
  resources: {
    en: {
      common: common_en, // 'common' is our custom namespace
    },
    id: {
      common: common_id,
    },
  },
});
function App() {
  return (
    <div style={{ overflowX: 'hidden' }}>
      <BrowserRouter>
        <AuthProvider>
          <I18nextProvider i18n={i18next}>
            <ToastContainer />
            <RouteList />
          </I18nextProvider>
        </AuthProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
