/* eslint-disable import/no-duplicates */
import { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import Form from 'react-bootstrap/Form';
import id from 'date-fns/locale/id';
import en from 'date-fns/locale/en-US';
import { Col, Row } from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import Button from '../../components/Button';
import InputCustom from '../../components/Input';
import Separator from '../../components/Separator';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import style from './style.module.css';
import { api } from '../../services/api';
import 'react-toastify/dist/ReactToastify.css';
import { SECONDARY_COLOR } from '../../constant';
import IcPH from '../../assets/img/putra-husada.webp';

interface IProps{
  hospitalID: string;
}

export default function Input({ hospitalID }: IProps) {
  const { t, i18n } = useTranslation('common');
  const [startDateDOB, setStartDateDOB] = useState<Date | null>(null);
  const [nationality, setNationality] = useState(null);
  const [province, setProvince] = useState([]);
  const [city, setCity] = useState([]);
  const [district, setDistrict] = useState([]);
  const [subDistrict, setSubDistrict] = useState([]);

  const [selectedProvince, setSelectedProvince] = useState({ label: null, value: null });
  const [selectedCity, setSelectedCity] = useState({ label: null, value: null });
  const [selectedDistrict, setSelectedDistrict] = useState({ label: null, value: null });
  const [selectedSubDistrict, setSelectedSubDistrict] = useState({ label: null, value: null });
  const genderOptions = [
    {
      value: 'male',
      label: t('general.male'),
    },
    {
      value: 'female',
      label: t('general.female'),
    },
  ];

  // Schema for yup
  const validationSchema = Yup.object().shape({
    nik: Yup.number().typeError(t('general.requiredPlace', { item: t('general.nik') })).test('len', t('general.must16digit'), (val) => val?.toString()?.length === 16),
    name: Yup.string()
      .required(t('general.requiredPlace', { item: t('general.name') })),
    address: Yup.string()
      .required(t('general.requiredPlace', { item: t('general.address') })),
  });

  // Schema for yup
  const validationSchemaForeign = Yup.object().shape({
    name: Yup.string()
      .required(t('general.requiredPlace', { item: t('general.name') })),
    address: Yup.string()
      .required(t('general.requiredPlace', { item: t('general.address') })),
  });

  async function loadProv() {
    try {
      const response = await api.get('/v1/province');
      const res = response?.data?.data?.map((item) => ({
        label: item.nama,
        value: item.id,
      }));
      setProvince(res);
      setCity([]);
      setDistrict([]);
      setSubDistrict([]);
    } catch (error) {
      setProvince([]);
      setCity([]);
      setDistrict([]);
      setSubDistrict([]);
    }
  }

  async function loadCity(provId) {
    try {
      const response = await api.get(`/v1/city/${provId}`);
      const res = response?.data?.data?.map((item) => ({
        label: item.nama,
        value: item.id,
      }));
      setCity(res);
      setDistrict([]);
      setSubDistrict([]);
    } catch (error) {
      setCity([]);
      setDistrict([]);
      setSubDistrict([]);
    }
  }

  async function loadDistrict(cityId) {
    try {
      const response = await api.get(`/v1/district/${cityId}`);
      const res = response?.data?.data?.map((item) => ({
        label: item.nama,
        value: item.id,
      }));
      setDistrict(res);
      setSubDistrict([]);
    } catch (error) {
      setDistrict([]);
      setSubDistrict([]);
    }
  }

  async function loadSubDistrict(districtId) {
    try {
      const response = await api.get(`/v1/subdistrict/${districtId}`);
      const res = response?.data?.data?.map((item) => ({
        label: item.nama,
        value: item.id,
      }));
      setSubDistrict(res);
    } catch (error) {
      setSubDistrict([]);
    }
  }

  useEffect(() => {
    loadProv();
  }, []);

  const submitForm = async ({ values, setSubmitting }) => {
    setSubmitting(true);
    try {
      const response = await api.post('/v1/auth/register', {
        name: values.name,
        nik: values.nationality === 'wna' ? values.passport : values.nik,
        gender: values.gender,
        birth_date: values.dob,
        address: values.address,
        organization: hospitalID,
        is_foreign: values.nationality === 'wna',
        province: values.province,
        city: values.city,
        district: values.district,
        sub_district: values.subdistrict,
      });
      const { status } = response;
      setSubmitting(false);
      if (Number(status) === 200) {
        toast.success(t('login.regisSuccess'), {
          position: toast.POSITION.TOP_CENTER,
          theme: 'colored',
        });
        window.location.assign('/dashboard');
        return;
      }
      Swal.fire({
        title: 'Oops!',
        text: t('general.errorMsg'),
        icon: 'error',
        confirmButtonText: t('general.reload'),
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.reload();
        }
      });
    } catch (error) {
      setSubmitting(false);
      const { response } = error;
      if (response.status !== 500) {
        Swal.fire({
          title: 'Oops!',
          text: response?.data?.message || response?.data?.errors?.[0]?.message || t('general.errorMsg'),
          icon: 'error',
          confirmButtonText: t('general.tryAgain'),
          allowOutsideClick: false,
        });
      } else {
        Swal.fire({
          title: 'Oops!',
          text: t('general.errorMsg'),
          icon: 'error',
          confirmButtonText: t('general.reload'),
          allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.reload();
          }
        });
      }
    }
  };

  const popupSubmit = (values, { setSubmitting }) => {
    setSubmitting(true);
    Swal.fire({
      html: `<div>
          <h5>${t('login.titlePopup')}</h5>
          ${t('login.textPopup')}
          <ul>
            <li>
              <a
                href="/privacy-policy"
                target="_blank"
                rel="noopener noreferrer"
              >
                ${t('login.listPopup1')}

              </a>
            </li>
            <li>
              <a
                href="/privacy-policy"
                target="_blank"
                rel="noopener noreferrer"
              >
                ${t('login.listPopup2')}
              </a>
            </li>
          </ul>
        </div>`,
      showCancelButton: true,
      input: 'checkbox',
      inputValue: 0,
      inputPlaceholder: t('login.checkPopup'),
      confirmButtonColor: 'green',
      cancelButtonColor: '#d33',
      confirmButtonText: t('general.agree'),
      cancelButtonText: t('general.cancel'),
      imageUrl: IcPH,
      imageWidth: 200,
      imageAlt: 'Logo',
      customClass: {
        htmlContainer: style.textJustify,
        input: style.containers,
      },
      inputValidator: (result) => !result && t('login.validPopup'),
      allowOutsideClick: false,
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        submitForm({ values, setSubmitting });
      } else {
        setSubmitting(false);
      }
    }).catch(() => {
      setSubmitting(false);
    });
  };

  const statusOptions = [
    {
      value: 'wni',
      label: t('general.wni'),
    },
    {
      value: 'wna',
      label: t('general.wna'),
    },
  ];

  return (
    <div style={{ width: '90%' }} className="mt-4">
      <h3 className="text-center mb-4 font-poppins-600" style={{ letterSpacing: '0.05em' }}>
        {t('login.titleRegis')}
      </h3>
      <p className="mb-4 text-center">
        {t('login.subtitleRegis')}

      </p>
      <Formik
        initialValues={{
          name: '', nik: null, gender: '', dob: '', address: '', nationality: null, passport: '',
        }}
        validationSchema={nationality === 'wna' ? validationSchemaForeign : validationSchema}
        onSubmit={popupSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldTouched,
          setFieldValue,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col sm={12}>
                <InputCustom
                  isSelect
                  options={statusOptions}
                  placeholder={t('general.nationality')}
                  label={t('general.nationality')}
                  className="w-100"
                  handleChange={(option) => {
                    setFieldValue('nik', null);
                    setFieldValue('passport', null);
                    setFieldTouched('nationality', true);
                    setFieldValue('nationality', option.value);
                    setNationality(option.value);
                  }}
                  name="nationality"
                  required
                />
                {values.nationality
                && (
                <div>
                  <Separator margin="20px" />
                  {values.nationality === 'wna'
                    ? (
                      <InputCustom
                        label="Passport"
                        type="text"
                        isBig
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        values={values.passport}
                        name="passport"
                        required={values.nationality === 'wna'}
                      />
                    )
                    : (
                      <>
                        <InputCustom
                          label={t('general.nik')}
                          type="number"
                          isBig
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          values={values.nik}
                          name="nik"
                          required={values.nationality === 'wni'}
                        />
                        {touched.nik && errors.nik ? <span className="error-msg">{errors.nik}</span> : ''}
                      </>
                    )}
                </div>
                ) }
                <Separator margin="20px" />
                <InputCustom
                  label={t('general.name')}
                  type="text"
                  isBig
                  handleChange={(e) => {
                    setFieldValue('name', e.target.value.toUpperCase());
                    setFieldTouched('name', true);
                  }}
                  handleBlur={handleBlur}
                  values={values.name}
                  name="name"
                />
                {touched.name && errors.name ? <span className="error-msg">{errors.name}</span> : ''}
                <Separator margin="20px" />
                <Form.Label className="font-poppins-400"><b>{t('general.dob')}</b></Form.Label>
                <DatePicker
                  showMonthDropdown
                  showYearDropdown
                  dateFormat="dd/MM/yyyy"
                  selected={startDateDOB}
                  onChange={(date:Date) => {
                    setFieldValue('dob', moment(date).format('YYYY-MM-DD'));
                    setFieldTouched('dob', true);
                    setStartDateDOB(date);
                  }}
                  locale={i18n.language === 'en' ? en : id}
                  className={`w-100 ${style[`input${hospitalID}`]} px-4 py-2`}
                  maxDate={new Date()}
                  withPortal
                  scrollableYearDropdown
                  yearDropdownItemNumber={80}
                  required
                />
                <Separator margin="20px" />
                <InputCustom
                  name="gender"
                  isSelect
                  options={genderOptions}
                  handleChange={(option) => {
                    setFieldTouched('gender', true);
                    setFieldValue('gender', option.value);
                  }}
                  placeholder={t('general.choocePlace', { item: t('general.gender') })}
                  label={t('general.gender')}
                  className="w-100"
                  required
                />
                <Separator margin="20px" />
                <InputCustom
                  name="province"
                  isSelect
                  options={province}
                  handleChange={(option) => {
                    setFieldTouched('province', true);
                    setFieldValue('province', option.label);
                    setSelectedProvince(option);
                    setSelectedCity({ label: null, value: null });
                    setSelectedDistrict({ label: null, value: null });
                    setSelectedSubDistrict({ label: null, value: null });
                    loadCity(option.value);
                  }}
                  isSearchable
                  placeholder={t('general.choocePlace', { item: t('general.province') })}
                  label={t('general.province')}
                  className="w-100"
                  required
                  values={selectedProvince}
                />
                <Separator margin="20px" />
                <InputCustom
                  name="city"
                  isSelect
                  options={city}
                  handleChange={(option) => {
                    setFieldTouched('city', true);
                    setFieldValue('city', option.label);
                    loadDistrict(option.value);
                    setSelectedCity(option);
                    setSelectedDistrict({ label: null, value: null });
                    setSelectedSubDistrict({ label: null, value: null });
                  }}
                  placeholder={t('general.choocePlace', { item: t('general.city') })}
                  label={t('general.city')}
                  className="w-100"
                  required
                  isSearchable
                  values={selectedCity}
                />
                <Separator margin="20px" />
                <InputCustom
                  name="district"
                  isSelect
                  options={district}
                  handleChange={(option) => {
                    setFieldTouched('district', true);
                    setFieldValue('district', option.label);
                    loadSubDistrict(option.value);
                    setSelectedDistrict(option);
                    setSelectedSubDistrict({ label: null, value: null });
                  }}
                  isSearchable
                  placeholder={t('general.choocePlace', { item: t('general.district') })}
                  label={t('general.district')}
                  className="w-100"
                  required
                  values={selectedDistrict}
                />
                <Separator margin="20px" />

                <InputCustom
                  name="subdistrict"
                  isSelect
                  isSearchable
                  options={subDistrict}
                  handleChange={(option) => {
                    setFieldTouched('subdistrict', true);
                    setFieldValue('subdistrict', option.label);
                    setSelectedSubDistrict(option);
                  }}
                  placeholder={t('general.choocePlace', { item: t('general.subDistrict') })}
                  label={t('general.subDistrict')}
                  className="w-100"
                  required
                  values={selectedSubDistrict}
                />
                <Separator margin="20px" />
                <InputCustom
                  label={t('general.address')}
                  isTextArea
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  values={values.address}
                  name="address"
                />
                {touched.address && errors.address ? <span className="error-msg">{errors.address}</span> : ''}
                <Separator margin="20px" />
              </Col>
            </Row>
            <Button
              type="submit"
              disabled={isSubmitting}
              bgColor={SECONDARY_COLOR}
              textColor="white"
              text={t('login.in')}
              className="p-3 w-100 mt-4 mb-4"
              loadingBg="#f39a8e"
              isLoading={isSubmitting}
            />
          </Form>
        )}
      </Formik>
    </div>
  );
}
