/* eslint-disable camelcase */
import {
  createContext, ReactNode, useEffect, useState, useContext, useMemo, Dispatch, SetStateAction,
} from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { api } from '../../services/api';
import { setAuthorizationHeader } from '../../services/interceptors';
import { getToken, removeTokenCookies } from '../../utils/tokenCookies';

interface User {
  gender: string
  last_login_organization: string
  name: string
  nik: string
  phone: string
  address: string
  birth_date: string
  is_foreign: boolean;
  province: any
  city: any
  district: any
  sub_district: any
}

interface AuthContextData {
  // signIn: (credentials: SignInCredentials) => Promise<void | AxiosError>
  signOut: (string, boolean) => void
  user: User
  isAuthenticated: boolean
  loadingUserData: boolean
  setUser: Dispatch<SetStateAction<User>>
  getUserData: any
}

interface AuthProviderProps {
  children: ReactNode
}

const AuthContext = createContext({} as AuthContextData);

function AuthProvider({ children }: AuthProviderProps) {
  const [user, setUser] = useState<User | null>();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loadingUserData, setLoadingUserData] = useState(true);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const token = getToken();
  const userData = user as User;

  // async function signIn({ email, password }: SignInCredentials) {
  //   try {
  //     const response = await api.post('/sessions', { email, password });
  //     const {
  //       token, refreshToken, permissions, roles,
  //     } = response.data;

  //     createTokenCookies(token, refreshToken);
  //     setUser({ email, permissions, roles });
  //     setAuthorizationHeader(api.defaults, token);
  //   } catch (error) {
  //     const err = error as AxiosError;
  //     return err;
  //   }
  // }

  function signOut(pathnames = '/', formLogoutBtn = false) {
    setLoadingUserData(true);
    removeTokenCookies();
    setUser(null);
    setLoadingUserData(false);
    if (formLogoutBtn) {
      navigate(pathnames);
    }
  }

  useEffect(() => {
    if (!token) signOut(pathname);
  }, [pathname, token]);

  async function getUserData() {
    setLoadingUserData(true);

    try {
      const response = await api.get('/v1/auth/me');
      if (response?.data?.data) {
        const {
          gender,
          last_login_organization,
          name,
          nik,
          phone,
          birth_date,
          address,
          is_foreign,
          province,
          city,
          district,
          sub_district,
        } = response.data.data;
        setUser({
          gender,
          last_login_organization,
          name,
          nik,
          phone,
          birth_date,
          address,
          is_foreign,
          province,
          city,
          district,
          sub_district,
        });
        setIsAuthenticated(true);
      }
    } catch (error) {
      setIsAuthenticated(false);
    }

    setLoadingUserData(false);
  }

  useEffect(() => {
    if (token) {
      setAuthorizationHeader(api.defaults, token);
      getUserData();
    }
  }, []);

  const authValue = useMemo(() => ({
    isAuthenticated,
    user: userData,
    loadingUserData,
    setUser,
    getUserData,
    signOut,
  }), [isAuthenticated, userData, loadingUserData, signOut]);

  return (
    <AuthContext.Provider value={authValue}>
      {children}
    </AuthContext.Provider>
  );
}

const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) throw new Error('useAuth must be used within a AuthProvider');
  return context;
};

export { useAuth, AuthProvider, AuthContext };
