import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import OtpField from 'react-otp-input';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import { Button } from 'react-bootstrap';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useTranslation } from 'react-i18next';
import ButtonComponent from '../../components/Button';
import OtpImage from '../../assets/img/otp.svg';
import style from './style.module.css';
import { api } from '../../services/api';
import 'react-toastify/dist/ReactToastify.css';
import { setAuthorizationHeader } from '../../services/interceptors';
import { createTokenCookies } from '../../utils/tokenCookies';
import { RS_COLOR } from '../../utils/color';
import { SECONDARY_COLOR } from '../../constant';

interface IProps{
  phone: number;
  hospitalID: string;
  onSuccess: (pageParam: string) => void;
}

function Otp({ hospitalID, phone, onSuccess }: IProps) {
  const { t } = useTranslation('common');
  const [value, setValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [timeLeft, setTimeLeft] = useState(60);

  useEffect(() => {
    if (phone) {
      if (!timeLeft) return;

      const intervalId = setInterval(() => {
        setTimeLeft((item) => item - 1);
      }, 1000);

      // eslint-disable-next-line consistent-return
      return () => clearInterval(intervalId);
    }
  }, [phone, timeLeft]);

  const onChange = (e) => {
    setValue(e);
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const response = await api.post('/v1/auth/verify', { phone, otp: value, organization: hospitalID });
      const { status, data } = response;
      setLoading(false);
      if (Number(status) === 200) {
        createTokenCookies(data.data.token, '');
        setAuthorizationHeader(api.defaults, data.data.token);
        toast.success(t('login.successOTP2'), {
          position: toast.POSITION.TOP_CENTER,
          theme: 'colored',
        });
        if (!data?.data?.registered) {
          onSuccess('input');
          return;
        }
        window.location.assign('/dashboard');
        return;
      }
      Swal.fire({
        title: 'Oops!',
        text: t('login.wrongOTP'),
        icon: 'error',
        confirmButtonText: t('general.tryAgain'),
        allowOutsideClick: false,
      });
    } catch (error: any) {
      const { response } = error;
      setLoading(false);
      if (response.status !== 500) {
        Swal.fire({
          title: 'Oops!',
          text: response?.data?.message || response?.data?.errors?.[0]?.message || t('general.errorMsg'),
          icon: 'error',
          confirmButtonText: t('general.tryAgain'),
          allowOutsideClick: false,
        });
      } else {
        Swal.fire({
          title: 'Oops!',
          text: t('general.errorMsg'),
          icon: 'error',
          confirmButtonText: t('general.reload'),
          allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.reload();
          }
        });
      }
    }
  };

  const reSendOTP = async () => {
    setLoading(true);
    try {
      const response = await api.post('/v1/auth/login', { phone, organization: hospitalID });
      const { status } = response;
      setLoading(false);
      if (Number(status) === 200) {
        toast.success(t('login.tryAgainOTPSuccess'), {
          position: toast.POSITION.TOP_CENTER,
          theme: 'colored',
        });
        setTimeLeft(60);
        return;
      }
      Swal.fire({
        title: 'Oops!',
        text: t('login.tryAgainOTP'),
        icon: 'error',
        confirmButtonText: t('general.tryAgain'),
        allowOutsideClick: false,
      });
    } catch (error: any) {
      const { response } = error;
      setLoading(false);
      if (response.status !== 500) {
        Swal.fire({
          title: 'Oops!',
          text: t('login.tryAgainOTP'),
          icon: 'error',
          confirmButtonText: t('general.tryAgain'),
          allowOutsideClick: false,
        });
      } else {
        Swal.fire({
          title: 'Oops!',
          text: t('general.errorMsg'),
          icon: 'error',
          confirmButtonText: t('general.reload'),
          allowOutsideClick: false,
        });
      }
    }
  };

  return (
    <>
      <h3 className="text-center mb-4 font-poppins-600" style={{ color: RS_COLOR[Number(hospitalID) - 1], letterSpacing: '0.05em' }}>
        <u>{t('login.inputOTP')}</u>
      </h3>
      <LazyLoadImage alt="image-register" src={OtpImage} width={250} className="mt-3" />
      <div className={`mt-4 text-center ${style['box-input']} ${style['box-width']}`}>
        <h5 className="font-poppins-600">{t('login.verifOTP')}</h5>
        <p>
          {t('login.subVerifOTP')}
        </p>
        <OtpField
          value={value}
          onChange={(e) => onChange(e)}
          numInputs={6}
          shouldAutoFocus
          isInputNum
          inputStyle={{
            border: 'none', borderBottom: '1px solid #555', margin: '10px 15px 0px',
          }}
          className={`mb-2 ${style.otp}`}
        />
        <p className="mt-4">
          {timeLeft > 0 ? (
            <b>
              {t('login.reloadOTP')}
              {' '}
              (
              {' '}
              {timeLeft}
              s
              {' '}
              )
            </b>
          )
            : t('login.noOTP')}
        </p>
        {timeLeft === 0
        && <p style={{ color: '#FFCC1D', cursor: 'pointer' }}><Button variant="primary" type="button" onClick={reSendOTP}>{t('login.reloadOTP')}</Button></p>}
        <ButtonComponent
          bgColor={SECONDARY_COLOR}
          textColor="white"
          text={t('login.in')}
          className="p-3 w-100 mt-4 mb-4"
          onClick={handleSubmit}
          isLoading={loading}
          disabled={loading}
          loadingBg="#f39a8e"
        />
      </div>
    </>
  );
}
export default React.memo(Otp);
