import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useTranslation } from 'react-i18next';
import ImageRegis from '../../assets/img/image-input.svg';
import Button from '../../components/Button';
import { api } from '../../services/api';
import 'react-toastify/dist/ReactToastify.css';
import style from './style.module.css';
import { RS_COLOR } from '../../utils/color';
import { SECONDARY_COLOR } from '../../constant';

interface IProps{
  onSuccess: (pageParam: string) => void;
  changePhone: (phoneParam: string) => void;
  hospitalID: string;
}
function Register({ onSuccess, changePhone, hospitalID }: IProps) {
  const { t } = useTranslation('common');
  // RegEx for phone number validation
  const phoneRegExp = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;

  // Schema for yup
  const validationSchema = Yup.object().shape({
    phone: Yup.string()
      .matches(phoneRegExp, t('login.hpNotValid'))
      .required(t('login.hpRequired')),
  });

  const submitForm = async (values, { setSubmitting }) => {
    setSubmitting(true);
    try {
      const response = await api.post('/v1/auth/login', {
        phone: `+62${values.phone}`,
        organization: hospitalID,
      });
      const { status } = response;
      setSubmitting(false);
      if (Number(status) === 200) {
        changePhone(`+62${values.phone}`);
        toast.success(t('login.successOTP'), {
          position: toast.POSITION.TOP_CENTER,
          theme: 'colored',
        });
        onSuccess('otp');
        return;
      }
      Swal.fire({
        title: 'Oops!',
        text: t('general.errorMsg'),
        icon: 'error',
        confirmButtonText: t('general.reload'),
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.reload();
        }
      });
    } catch (error) {
      setSubmitting(false);
      const { response } = error;
      if (response.status !== 500) {
        Swal.fire({
          title: 'Oops!',
          text: response?.data?.message || response?.data?.errors?.[0]?.message || t('general.errorMsg'),
          icon: 'error',
          confirmButtonText: t('general.tryAgain'),
          allowOutsideClick: false,
        });
      } else {
        Swal.fire({
          title: 'Oops!',
          text: t('general.errorMsg'),
          icon: 'error',
          confirmButtonText: t('general.reload'),
          allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.reload();
          }
        });
      }
    }
  };

  return (
    <>
      <h3 className="text-center mb-4 font-poppins-600" style={{ color: RS_COLOR[Number(hospitalID) - 1], letterSpacing: '0.05em' }}>
        <u>{t('login.daftar')}</u>
      </h3>
      <LazyLoadImage alt="image-register" src={ImageRegis} width={250} className="mt-3" />
      <div className={`mt-4 ${style['box-width']}`}>
        <h5 className="font-poppins-600">{t('login.daftar2')}</h5>
        <p>{t('login.daftar3')}</p>
        <Formik
          initialValues={{ phone: '' }}
          validationSchema={validationSchema}
          onSubmit={submitForm}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <Form onSubmit={handleSubmit}>
              <div className="mb-3 ">
                <InputGroup>
                  <InputGroup.Text className={`input-group-text${hospitalID} p-3`}>+62</InputGroup.Text>
                  <Form.Control
                    name="phone"
                    className={`input-text${hospitalID} p-3 ${touched.phone && errors.phone ? 'error' : ''}`}
                    autoFocus
                    required
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.phone}
                    autoComplete="new-off"
                  />
                </InputGroup>
                {touched.phone && errors.phone ? <span className="error-msg">{errors.phone}</span> : ''}
              </div>
              <Button
                type="submit"
                disabled={isSubmitting}
                bgColor={SECONDARY_COLOR}
                textColor="white"
                text={t('login.getOTP')}
                className="p-3 w-100 mt-4 mb-4"
                loadingBg="#f39a8e"
                isLoading={isSubmitting}
              />
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
}

export default React.memo(Register);
